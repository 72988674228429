import services from "./index";
import qs from "querystring";

export default {
    get(id) {
        return services.http.get("/v1.0/account/" + id);
    },
    linkSlack(code, scope) {
        return services.http.post(`/v1.0/oauth/link-slack?code=${code}&scope=${scope}`, {});
    },
    linkTeams(code, scope) {
        return services.http.post(`/v1.0/oauth/link-teams?code=${code}&scope=${scope}`, {});
    },
    createToken() {
        return services.http.post(`/v1.0/oauth/create-token`, {});
    },
    createCookie(idToken) {
        return services.http.post(`/v1.0/oauth/create-cookie`, { id_token: idToken}, this.getConfig(idToken));
    },
    login(email, password) {
        return services.http.post(`/v1.0/oauth/login`, { email: email, password: password, create_cookie: false}, { withCredentials: false });
    },
    register(email, password, displayName) {
        return services.http.post(`/v1.0/oauth/register`, { email: email, password: password, display_name: displayName, create_cookie: false }, { withCredentials: false });
    },

    getConfig(idToken) {
        return {
            headers: {
                authorization: `Bearer ${idToken}`,
            },
            //withCredentials: true,
        };
    },
}